import React, { useState } from 'react';
import styled from 'styled-components';
import ModalVideo from 'react-modal-video';
import { getYoutubeId } from '../../../utils/getYoutubeId';
import PlayIcon from '../../../images/Glass-Play-Button.png';

export function HeroNeedsVideo({ component, children, ...rest }) {
  const [play, setPlay] = useState(false);

  function handleChangePlay() {
    setPlay(!play);
  }

  if (component.videoLink) {
    return (
      <StyledContainer {...rest} onClick={handleChangePlay}>
        {children}
        {typeof window !== 'undefined' && (
          <ModalVideo
            channel="youtube"
            isOpen={play}
            videoId={getYoutubeId(component.videoLink)}
            onClose={handleChangePlay}
          />
        )}
        <PlayButton>
          <img src={PlayIcon} />
        </PlayButton>
      </StyledContainer>
    );
  } else return children;
}

const StyledContainer = styled.div`
  cursor: pointer;
  position: relative;
`;

const PlayButton = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  border-radius: 150px;
  transform: translate(-50%, -50%);
  display: flex;
  justify-content: center;
  align-items: center;
  pointer-events: none;

  img {
    height: 152px;
    width: 152px;
  }
`;
