import clsx from 'clsx';
import React, {
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState,
} from 'react';
import ModalVideo from 'react-modal-video';
import { DisclaimerContext } from '../../../contexts/DisclaimersContext';
import Section from '../../../molecules/Section/Section';
import { getYoutubeId } from '../../../utils/getYoutubeId';
import HeaderNoMenu from '../../Header/HeaderNoMenu';
import HeroContent from '../components/HeroContent';
import HeroDisclaimer from '../components/HeroDisclaimer';
import HeroLottie from '../components/HeroLottie';
import HeroMedia from '../components/HeroMedia';
import HeroStyles, {
  HeroContentWrapper,
  MediaWrapper,
  customInnerContentStyles,
  customSectionStyles,
} from '../styles/HeroNewStyles';
import { handleLottieResize } from '../utils/helpers';
import useHeroConditionals from '../utils/useHeroConditionals';
import { HeroNeedsVideo } from '../components/HeroNeedsVideo';
import SocialProof from '../components/HeroSocialProof';

const Hero = ({
  centeredImage = false,
  component,
  disableLogoNavigation,
  isWithMenu,
  logo,
}) => {
  const {
    background,
    demoDisclaimers,
    expandMedia,
    hasHeroDisclaimer,
    id,
    nextSectionColor,
    reference,
    showLogoDisclaimer,
    verticallyCentered,
    showVideo,
    videoLink,
  } = component;

  const { setIsDisclaimerShown } = useContext(DisclaimerContext);
  const [play, setPlay] = useState(false);
  const lottieRef = useRef();
  const heroRef = useRef();
  const playVideo = useCallback(() => setPlay(true), []);
  const {
    colorMap,
    isAnimated,
    isBlue,
    isFullWidth,
    isIntegration,
    isLanding,
    isSmaller,
    mobileFormFlag,
  } = useHeroConditionals(component);

  useEffect(() => {
    handleLottieResize(component, heroRef, lottieRef, isSmaller);
  });

  useEffect(() => {
    setIsDisclaimerShown(showLogoDisclaimer);
  }, [setIsDisclaimerShown, showLogoDisclaimer]);
  return (
    <>
      <Section
        id={id}
        className={clsx(
          !isWithMenu && 'withOutMenu',
          isAnimated && 'animated',
          isLanding && 'landing',
          isBlue && 'blue'
        )}
        innerClassName={clsx('hero-container', isFullWidth && 'full-width')}
        style={customSectionStyles}
        innerStyle={customInnerContentStyles}
        hasSvgHexes
        bgColor={colorMap?.bg}
      >
        <HeroStyles
          centeredImage={centeredImage}
          nextSectionColor={nextSectionColor}
          ref={heroRef}
        >
          {isAnimated && (
            <HeroLottie component={component} lottieRef={lottieRef} />
          )}
          {!isWithMenu && (
            <HeaderNoMenu
              background={background}
              disableLogoNavigation={disableLogoNavigation}
              isFullWidth={isFullWidth}
              isHidden={!isBlue && isLanding}
              logo={logo}
            />
          )}
          <HeroContentWrapper
            centeredImage={centeredImage}
            isFullWidth={isFullWidth}
            verticallyCentered={verticallyCentered}
          >
            <HeroContent
              centeredImage={centeredImage}
              colorMap={colorMap}
              component={component}
              isFullWidth={isFullWidth}
              isIntegration={isIntegration}
              mobileFormFlag={mobileFormFlag}
            />
            {reference && reference.length > 0 && (
              <MediaWrapper
                centeredImage={centeredImage}
                expandMedia={expandMedia}
                isFullWidth={isFullWidth}
                isWithMenu={isWithMenu}
              >
                <HeroNeedsVideo component={component}>
                  <HeroMedia
                    component={component}
                    mobileFormFlag={mobileFormFlag}
                  />
                </HeroNeedsVideo>
              </MediaWrapper>
            )}
          </HeroContentWrapper>
          {hasHeroDisclaimer && (
            <HeroDisclaimer disclaimerList={demoDisclaimers?.blocks} />
          )}
          {typeof window !== 'undefined' && (
            <ModalVideo
              channel="youtube"
              isOpen={play}
              videoId={getYoutubeId(reference[0]?.youtubeUrl)}
              onClose={() => setPlay(false)}
            />
          )}
        </HeroStyles>
      </Section>
      <Section>
        {component.socialProof && (
          <SocialProof
            style={{
              display: 'center',
              justifyContent: 'center',
              alignItems: 'center',
            }}
            isCentered={
              !(component.reference && component.reference.length > 0)
            }
            colorMap={colorMap}
          />
        )}
      </Section>
    </>
  );
};

export default Hero;
